import { nextTick } from 'vue';
import { isClient, usePreferredReducedMotion } from '@vueuse/core';

export const useAnchorTag = () => {
  const prefersReducedMotion = usePreferredReducedMotion();

  /**
   * Validates if the current route is an anchor route.
   * It checks if the paths are the same and the hashes are different.
   * If the hashes are the same, it checks if the hash is an anchor.
   */
  const validateAnchorRoute = (to, from) => {
    const isSamePath = to.path === from.path;
    const isDifferentHash = to.hash !== from.hash;
    const isSameHashWithAnchor = to.hash && to.hash === from.hash;
    return isSamePath && (isDifferentHash || isSameHashWithAnchor);
  };

  /**
   * Scrolls to the element specified by the current route's hash.
   * It first tries to find an element with the matching ID.
   * If not found, it searches for an element with a `data-anchor-tag` attribute.
   * If either is found, it scrolls to the element using `scrollIntoView`.
   */
  const scrollToAnchor = async (hash) => {
    if (!isClient || !hash) return;

    await nextTick();
    let targetElement = document.querySelector(hash);

    if (!targetElement) {
      const anchorId = hash.slice(1);
      targetElement = document.querySelector(`[data-anchor-tag="${anchorId}"]`);
    }

    if (!targetElement) return;

    targetElement.scrollIntoView({
      behavior: prefersReducedMotion.value === 'no-preference' ? 'smooth' : 'auto',
    });
  };

  return {
    validateAnchorRoute,
    scrollToAnchor,
  };
};
